<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="createForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          @submit="submit"
          :form="$refs.createForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data">
            <template #lines="item">
              <div
                v-for="(requestItem, idx) in data.lines"
                :key="`item-${idx}`"
              >
                <b-row>
                  <b-col sm="6">
                    <label>
                      ITEM</label>
                    <b-form-input
                      v-model="requestItem.label"
                      placeholder="Enter item name"
                      :disabled="true"
                    >
                    </b-form-input>
                  </b-col>
                  <b-col sm="6">
                    <label class="w-100">
                      <label>QUANTITY</label>
                      <b-link @click="removeSelectedItem(idx)" class="link-underline text-danger float-right">Delete</b-link>
                    </label>
                    <b-form-input v-model="requestItem.request_quantity" placeholder="Enter item quantity">
                    </b-form-input>
                  </b-col>
                </b-row>
                <div class="divider-line my-2"></div>
              </div>
              <div v-if="data.lines.length == 0"></div>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :type="permissionType"
                v-if="$can('create', permissionType)"
              ></n-button-save-option>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import Swal from 'sweetalert2';

const purchaseReqeustReportRepository = Repository.get("purchaseRequestReport");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    BButton,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NButtonSaveOption,
    Swal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        requestedBy: null,
        companyId: null,
        departmentId: null,
        requestedDate: null,
        expectedDate: null,
        remark: null,
        itemId: null,
        lines: [],
      },
      loading: false,
    };
  },
  watch: {
    "data.itemId"(value) {
      
      if (this.checkIfItemExist(value)) {
        Swal.fire({
          title: 'Duplicated Selection!',
          icon: 'info',
          text: 'This item has already been selected. Please select another item',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } else {
        let item = {
          'item_id' : value.id,
          'request_quantity' : 0,
          'label' : value.label
        };
        this.data.lines.push(item);
      }
      //this.data.itemId = null;
    },

  },
  methods: {
    checkIfItemExist(value) {
      let selecteds = this.data.lines;
      
      for (let i = 0; i < selecteds.length; i++) {
        console.log(selecteds[i].item_id);
        if (selecteds[i].item_id == value.id) {
          return true;
        }
      }
      return false;
    },
    removeSelectedItem(index) {
      this.data.lines.splice(index, 1);
    },
    submit(type) {
      this.$refs.createForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;

      purchaseReqeustReportRepository.create(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.createForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = FormInput;
    const permissionType = "purchase-request-report";

    return { fields, permissionType };
  },
};
</script>